
    import Vue from "vue";
    import Component from "vue-class-component";
    import store from "@/store/store";
    import { ILanguage } from "@/model/Language";

    @Component({
        components: { 

        }
    })

    export default class Translations extends Vue {

        async mounted(): Promise<void> {
            if (store.state.retailer) this.retailer = store.state.retailer;
            if (store.state.languages) this.languages = store.state.languages;
        }

        //
        // -- properties
        //

        retailer = 0;
        private languages: Array<ILanguage> = [];
    }
    
